import React from "react";

import { Row, Col } from "reactstrap";

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaInstagram } from "react-icons/fa";
import { MdOutlineEmail, MdPhoneAndroid} from "react-icons/md";
import QRInsta from "../assets/qr-insta.webp";

const Footer = (props) => {

  const { isNotMobile, sendZap } = props;

  const checkZap = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=5514996307249`,
      '_blank'
    );
  }

  let aStyle = {
    fontSize: isNotMobile ? '30px' : '20px', 
    height: isNotMobile ? '100px' : '50px'
  }

  return (
    <div className={`box-footer ${isNotMobile ? 'gutter-top' : ''}`}>
      <Row>
        <Col md={12} xs={12} className="cta-footer">
          <h1 className="white text-center" style={{ fontSize: isNotMobile ? '44px' : '16px' }}>
            Dê um boost na sua identidade visual <br/> com o para<b>doutores</b>
          </h1>

          <div className="space" />

          {sendZap("btn-cta", aStyle)}
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12} className="gutter-top">
          <Row>
            <Col md={4} xs={12} className={!isNotMobile ? "text-center" : ""}>
              <span className="span-brand">para<b>doutores</b></span><br/><br/>
              <a className="link-email" href="mailto:paradoutores@gmail.com?subject=paradoutores - Orçamento">
                <MdOutlineEmail /> paradoutores@gmail.com
              </a><br/><br/>
              <p 
                style={{cursor: 'pointer'}} 
                onClick={() => checkZap()}
              >
                <MdPhoneAndroid /> 14 99630 7249
              </p>
            </Col>

            <Col md={4} xs={12} 
              style={{
                fontSize: !isNotMobile ? '14px' : '16px'
              }}
            >
              <p> Sitemap </p>

              <AnchorLink href='#header' className="nav-anchor">
                Início
              </AnchorLink>

              <AnchorLink href='#features' className="nav-anchor">
                Recursos
              </AnchorLink>

              <AnchorLink href='#suitable' className="nav-anchor">
                Áreas da Medicina
              </AnchorLink>

              <AnchorLink href='#jobs' className="nav-anchor">
                Criações
              </AnchorLink>
            </Col>

            <Col
              id="insta" 
              md={4} xs={12} 
              style={{display: 'grid', justifyContent: 'center', paddingTop: isNotMobile ? '' : '3em'}}
            >
              <p>Siga no <FaInstagram /> Instagram!</p>

              <img alt="qrcode instagram" src={QRInsta} className="qr-insta" />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="space" />

      <hr />

      <Row>
        <Col md={12} xs={12} className="text-center">
          <p style={{fontSize: !isNotMobile ? "14px" : ""}}>
            para<b>doutores</b> @ {new Date().getFullYear()} - Todos os direitos reservados
          </p>
        </Col>
      </Row>
      
    </div>
  )
}

export default Footer;