import React from "react";

import { Button, Row, Col } from "reactstrap";

import { FaCheckCircle } from "react-icons/fa";

const Suitable = (props) => {

  const { isNotMobile, sendZap } = props;

  let aAreas = [
    {
      id: 1,
      title: "Clínicas"
    },
    {
      id: 2,
      title: "Hospitais"
    },
    {
      id: 3,
      title: "Consultórios"
    },
    {
      id: 4,
      title: "Veterinários"
    },
    {
      id: 5,
      title: "Dentistas"
    },
    {
      id: 6,
      title: "Startups"
    },
  ]

  let aStyle = {
    fontSize: isNotMobile ? '30px' : '20px', 
    height: isNotMobile ? '100px' : '50px',
  };

  return (
    <div className="suitable">
      <Row>
        <Col md={12} xs={12} className="text-center">
          <h5 className="light-blue">Adequado para áreas da Medicina</h5><br/>

          <h1 style={{fontSize: isNotMobile ? '40px' : '22px'}}>
            Total flexibilidade e melhor abordagem de pacientes
          </h1>
        </Col>
      </Row>

      {isNotMobile && <div className="space" />}

      <Row>
        <Col md={12} xs={12}>
          <Row>
            {aAreas.map((area) => {

              return (
                <Col key={area.id} md={4} xs={12} className="box-areas">
                  <Button 
                    outline 
                    color="primary" 
                    className="btn-suitable" 
                    size={isNotMobile ? "lg" : "md"}
                    style={{marginTop: isNotMobile ? '70px' : '25px'}}
                  >
                    <span className="suitable-button" style={{fontSize: isNotMobile ? '40px' : ''}}>
                      <FaCheckCircle color="#4eb2f9" size={30} />
                      &nbsp; {area.title}
                    </span>
                  </Button>
                </Col>
              )
            })}
          </Row>

          <div className="space" />

          <Row>
            <Col md={12} xs={12}>
              {sendZap("btn-cta", aStyle)}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Suitable;