import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import CNavbar from "./components/navbar";
import CBanner from "./components/banner";
import Features from "./components/features";
import Suitable from "./components/suitable";
import Jobs from "./components/jobs";
import Custom from "./components/custom";
import Footer from "./components/footer";
import { FaWhatsapp } from "react-icons/fa";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const matchMedia = window.matchMedia(`(min-width: 992px)`);
  const [isNotMobile, setIsNotMobile] = useState(matchMedia.matches);

  //img cartao boa - https://i.imgur.com/PpWGmdx.png

  const sendZap = (className, style, navbar) => {
    let msg = `Olá! Vi o site paradoutores e gostaria de falar com você. Pode me ajudar?`;
    let url = `https://api.whatsapp.com/send/?phone=5514996307249&text=${msg}`;

    //eslint-disable-next-line
    {/*
      //as button, btn bkp
      <Button
        color="primary" 
        className="btn-cta-blue"
        onClick={() => sendZap()} 
        style={{fontSize: isNotMobile ? '30px' : '20px', height: isNotMobile ? '100px' : '50px'}}
      >
        <span>
          <FaWhatsapp /> &nbsp; {isNotMobile ? 'Quero um orçamento' : 'Contato'}
        </span>
      </Button> 
      //then function
      window.open(
        `https://api.whatsapp.com/send/?phone=5514996307249&text=${msg}`,
        '_blank'
      );
    */}

    //as link
    return (
      <a 
        href={url} 
        target="_blank"
        rel="noreferrer"
        style={style} 
        className={className}
      >
        <span style={{display: "flex", alignItems: "center"}}>
          <FaWhatsapp /> &nbsp; {navbar ? 'Contato' : isNotMobile ? 'Quero um orçamento' : 'Contato'}
        </span>
      </a>
    )
  }

  useEffect(() => {
    matchMedia.addListener(() => {
      setIsNotMobile(matchMedia.matches);
    });
  }, [matchMedia]);

  return (
    <div className="app" id="header">
      <div className="container">
        <Row>
          <Col md={12} xs={12}>
            <CNavbar isNotMobile={isNotMobile} sendZap={sendZap} />
          </Col>
        </Row>

        <div className="space" />

        <Row>
          <Col md={12} xs={12}>
            <CBanner isNotMobile={isNotMobile} />
          </Col>
        </Row>
      </div>

      <div className="space" />

      <div className="main container-fluid box-features">
        <Row>
          <Col md={12} xs={12}>
            <Features isNotMobile={isNotMobile} />
          </Col>
        </Row>
      </div>

      {isNotMobile && <div className="space" />}

      <div className="main container" id="suitable">
        <Row>
          <Col md={12} xs={12}>
            <Suitable isNotMobile={isNotMobile} sendZap={sendZap} />
          </Col>
        </Row>
      </div>

      <div className="space" />

      <div className="main container-fluid">
        <Row>
          <Col md={12} xs={12}>
            <Jobs isNotMobile={isNotMobile} />
          </Col>
        </Row>
      </div>

      <div className="space" />

      <div className="main container">
        <Row>
          <Col md={12} xs={12}>
            <Custom isNotMobile={isNotMobile} />
          </Col>
        </Row>
      </div>

      <div className="space" />

      <div className="footer container">
        <Row>
          <Col md={12} xs={12}>
            <Footer isNotMobile={isNotMobile} sendZap={sendZap} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default App;
