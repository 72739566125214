import React from "react";
import { Button } from "reactstrap";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const CBanner = (props) => {

  const { isNotMobile } = props;

  return (
    <div className="banner-box">
      <h1 className="header" style={{ fontSize: isNotMobile ? '60px' : '30px' }}>
        A comunicação ideal <br/> com seus pacientes
      </h1><br/>

      <h3>Sua identidade visual com detalhes únicos</h3><br/>

      <h4 style={{ fontSize: isNotMobile ? '24px' : '18px' }}>
        O para<b>doutores</b> personaliza e cria layouts que ajudam <br/>
        você a extrair o melhor da sua clínica e hospital
      </h4>

      <div className="space" />

      <div className="banner-action"><br/><br/>
        <AnchorLink href='#features' className="explore-features">
          <Button size="lg" color="primary">
            Explorar
          </Button>        
        </AnchorLink>
      </div>

    </div>
  )
}

export default CBanner;