import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
} from 'reactstrap';

import AnchorLink from 'react-anchor-link-smooth-scroll';

const CNavbar = (props) => {
  const { isNotMobile, sendZap } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  let aStyle = {
    color: "#fff"
  }

  return (
    <div>
      <Navbar {...props} expand={isNotMobile}>
        <NavbarBrand href="/">
          <span className="span-brand">para<b>doutores</b></span>
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto me-nav" navbar>
            <NavItem>
              <AnchorLink href='#features' className="nav-anchor">
                Recursos
              </AnchorLink>
            </NavItem>

            <NavItem>
              <AnchorLink href='#suitable' className="nav-anchor">
                Áreas da Medicina
              </AnchorLink>
            </NavItem>

            <NavItem>
              <AnchorLink href='#jobs' className="nav-anchor">
                Criações
              </AnchorLink>
            </NavItem>
          </Nav>

          <NavbarText>
            {sendZap("btn btn-primary btn-lg", aStyle, true)}
          </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default CNavbar;