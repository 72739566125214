import React from "react";

import { Row, Col } from "reactstrap";
import Image1 from "../assets/doctoralia.webp";
import Image2 from "../assets/set-icones.webp";

const Custom = (props) => {

  const { isNotMobile } = props;

  return (
    <div className="custom">
      <Row>
        <Col md={6} xs={12} style={{display: "grid", justifyContent: "end", alignItems: "center"}}>
          <div className="space" />
          <h1 className="header" style={{textAlign: !isNotMobile ? 'center' : ''}}>
            Link exclusivo com o seu perfil Doctoralia
          </h1>

          {!isNotMobile && <br/>}
          <h4 style={{ color: '#536288', fontSize: isNotMobile ? '22px' : '16px', textAlign: !isNotMobile ? 'center' : '' }}>
            Agilize o agendamento dos pacientes com seu cartão digital, direcionando 
            o atendimento para o local correto.
          </h4>
        </Col>

        <Col md={6} xs={12} style={{display: 'flex', justifyContent: 'start', marginTop: isNotMobile ? '' : '2em'}}>
          <div className="space" />
          <img className="job-hero" alt="doctoralia" src={Image1} />
        </Col>        
      </Row>

      <div className="space" />

      <Row className="gutter-top">
        <Col md={6} xs={12} style={{display: 'flex', justifyContent: 'end'}}>
          <div className="space" />
          <img className="job-hero" alt="doctoralia" src={Image2} />
        </Col>   

        <Col md={6} xs={12} style={{display: "grid", alignItems: "flex-start"}}>
          <div className="space" />
          <h1 className="header" style={{textAlign: !isNotMobile ? 'center' : ''}}>Set de ícones para Medicina</h1>

          {!isNotMobile && <br/>}

          <h4 style={{ color: '#536288', fontSize: isNotMobile ? '22px' : '18px', textAlign: !isNotMobile ? 'center' : '' }}>
            Seu cartão digital ou logotipo inclui uma série de ícones personalizados,
            para deixar ainda mais fácil a visualização do seu trabalho.
          </h4>
        </Col>     
      </Row>
    </div>

  )
}

export default Custom;