import React, { useState } from "react";

import { Row, Col, Modal, Button, ModalHeader, ModalBody } from "reactstrap";
import Image1 from "../assets/logo-arthur-souza.webp";
import Image2 from "../assets/cartao-digital-arthur-souza.webp";
import Image3 from "../assets/cartao-digital-jean-coutinho.webp";
import Image4 from "../assets/logo-ruana-belmont.webp";
import Image5 from "../assets/pasta-jean-coutinho.webp";
import CCarousel from "./cCarousel";

const Jobs = (props) => {

  const { isNotMobile } = props;
  const [toggler, setToggler] = useState(false);
  const [img, setImg] = useState({
    img: '',
    key: '0'
  });

  let images = [
    {
      src: Image1,
      altText: 'Logotipo Arthur Souza',
      caption: 'Logotipo Arthur Souza',
      key: 0,
    },
    {
      src: Image2,
      altText: 'Cartão Digital Dr. Arthur Souza',
      caption: 'Cartão Digital Dr. Arthur Souza',
      key: 1,
    },
    {
      src: Image3,
      altText: 'Cartão Digital Dr. Jean Coutinho',
      caption: 'Cartão Digital Dr. Jean Coutinho',
      key: 2,
    },
    {
      src: Image4,
      altText: 'Logotipo Dra. Ruana Belmont',
      caption: 'Logotipo Dra. Ruana Belmont',
      key: 3,
    },
    {
      src: Image5,
      altText: 'Pasta para exames Dr. Jean Coutinho',
      caption: 'Pasta para exames Dr. Jean Coutinho',
      key: 4,
    }
  ];

  const handleImg = (image, key) => {
    setToggler(!toggler);
    setImg({
      img: image,
      key: key
    });
  }

  return (
    <div className="jobs" id="jobs" style={{ padding: isNotMobile ? '50px' : '10px' }}>
      <Row>
        <Col md={12} xs={12} className="text-center">
          <h5 className="light-blue">Prévia de outros trabalhos</h5><br />

          <h1 style={{ fontSize: isNotMobile ? '40px' : '16px' }}>Clique em algumas criações feitas por nós</h1>
        </Col>
      </Row>

      <div className="space" />

      <Row>
        <Col md={12} xs={12}>
          <CCarousel images={images} handleImg={handleImg} />
        </Col>

        <Col md={4} xs={12} className="text-center">
          <Modal 
            isOpen={toggler}
            fullscreen={true}
            contentClassName="gallery-modal" 
            toggle={() => setToggler(!toggler)}
          >
            <ModalHeader 
              toggle={() => setToggler(!toggler)}
            >
              {images[img.key].caption}
            </ModalHeader>

            <ModalBody>
              <img 
                className="img-modal" 
                src={images[img.key].src} 
                alt={images[img.key].caption} 
              />
            </ModalBody>
            
            <Button 
              color="primary" 
              onClick={() => setToggler(!toggler)}
              className="btn-footer-close"
            >
              Fechar
            </Button>
          </Modal>
        </Col>
      </Row>
    </div>
  )
}

export default Jobs;