import React from "react";

import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { RxDragHandleDots2 } from "react-icons/rx";
import { RiFontSize } from "react-icons/ri";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineDesignServices } from "react-icons/md";
import { HiOutlineChartBar } from "react-icons/hi";

const Features = (props) => {

  const { isNotMobile } = props;

  let aFeatures = [
    {
      id: 1,
      icon: <FaExternalLinkAlt size={80} />,
      title: "Links Interativos",
      subtitle: "",
      text: "Direcione seu paciente para a página mais indicada de acordo com a demanda",
    },
    {
      id: 2,
      icon: <RxDragHandleDots2 size={80} />,
      title: "Design Atômico",
      subtitle: "",
      text: "Desenvolvimento moderno da sua identidade seguindo o design atômico",
    },
    {
      id: 3,
      icon: <RiFontSize size={80} />,
      title: "Biblioteca de Fontes",
      subtitle: "",
      text: "Várias fontes para você escolher qual combina mais com seu projeto",
    },
    {
      id: 4,
      icon: <BsGrid1X2 size={80} />,
      title: "Layout Responsivo",
      subtitle: "",
      text: "Criações que se encaixam perfeitamente em todos os dispositivos",
    },
    {
      id: 5,
      icon: <MdOutlineDesignServices size={80} />,
      title: "Arquivos Editáveis",
      subtitle: "",
      text: "Além da imagem, todos os arquivos são seus, para utilizar em campanhas físicas",
    },
    {
      id: 6,
      icon: <HiOutlineChartBar size={80} />,
      title: "Mostre sua marca",
      subtitle: "",
      text: "A comunicação eficiente aumenta o engajamento dos pacientes com sua clínica",
    },
  ]

  return (
    <div className="features container" id="features" 
      style={{padding: isNotMobile ? '40px 100px' : '50px 0px'}}>
      <Row>
        <Col md={12} xs={12} className="text-center">
          <h5 className="light-blue">Principais Recursos</h5><br/>

          <h1 style={{fontSize: isNotMobile ? '40px' : '18px'}}>Aqui existem vários recursos disponíveis</h1>
        </Col>
      </Row>

      {isNotMobile && <div className="space" />}

      <Row>
        {aFeatures.map((feature) => {
          return (
            <Col 
              key={feature.id} 
              lg={4} md={4} xs={12} sm={12}
              className="feature text-center"
            >
              <Card className="card-text">
                <CardBody>
                  <div className="icon-feature">
                    {feature.icon}
                  </div>

                  <div className="space" />
                  
                  <CardTitle tag="h5">
                    {feature.title}
                  </CardTitle>

                  <CardText>
                    {feature.text}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default Features;